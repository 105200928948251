import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import Layout from '../../components/layout';
import styles from './404.module.sass';
import SEO from '../../components/common/seo';

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="404 Not Found"
        descriptions="We are sorry but the page you were looking for was not found."
        urlPath="/404/"
      />
      <section className={styles.section}>
        <div className={cx('container', styles.container)}>
          <h1 className={styles.heading}>404 Not Found</h1>
          <p>We are sorry but the page you were looking for was not found.</p>
          <Link className="pill-button" to="/">
            Go to Homepage
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
